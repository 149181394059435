import { Ribbon } from "./_Ribbon_class.js"; // Importiere die Ribbon Klasse

const path = "https://bonn.bildwerk.tv/static/data/letters.json";
let letters = [];
let newLetters = [];

// Lade die JSON-Daten und aktualisiere das 'letters' Array
async function getData() {
  try {
    const response = await fetch(`${path}?t=${new Date().getTime()}`);
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }

    const json = await response.json();
    newLetters = Object.values(json);

    if (letters.length === 0) {
      letters = [...newLetters]; // Initialisiere letters mit den neuen Briefen
    } else {
      const latestNewLetter = newLetters[newLetters.length - 1];
      if (latestNewLetter !== letters[letters.length - 1]) {
        ribbons.unshift(
          new Ribbon(
            p,
            50,
            p.random(50, p.height - 50),
            "white",
            latestNewLetter,
            letters,
            ribbonColors
          )
        );
        letters.push(latestNewLetter); // Füge den neuesten Brief zu letters hinzu
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}

// JSON-Daten initial laden
getData();

const ribbonWrapper = document.getElementById("ribbon-wrapper");
if (ribbonWrapper) {
  if (ribbonWrapper.getAttribute("data-museum") === "true") {
    getData();
    setInterval(getData, 1000); // Alle 1 Sekunden die JSON-Daten aktualisieren
  }

  import("p5").then(function (p5) {
    let ribbons = [];
    const ribbonColors = [
      "#BAE229",
      "#FFEF2C",
      "#75D2F2",
      "#DEB3E8",
      "#FFB42E",
      "#9AE5C6",
      "#FF7878",
    ];

    const ribbonSketch = function (p) {
      p.setup = function () {
        const wrapper = document.getElementById("ribbon-wrapper");
        let myCanvas = p.createCanvas(wrapper.offsetWidth, window.innerHeight);
        myCanvas.parent("ribbon-wrapper");
        p.background(0);
        p.cursor(p.CROSS);

        for (let i = 0; i < 4; i++) {
          ribbons.push(
            new Ribbon(
              p,
              -50,
              p.random(p.height),
              null,
              null,
              letters,
              ribbonColors
            )
          );
        }

        let observer = new IntersectionObserver(
          function (entries) {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                p.loop();
              } else {
                p.noLoop();
              }
            });
          },
          { threshold: 0.1 }
        );

        observer.observe(wrapper);

        // Event Listener für das Input-Feld und den Submit-Button
        const ribbons_input = document.getElementById("ribbons_input");
        const submitBtn = document.getElementById("submitBtn");

        if (ribbons_input && submitBtn) {
          submitBtn.addEventListener("click", async function (event) {
            await submitForm(event);
          });

          ribbons_input.addEventListener("keydown", function (event) {
            if (event.key === "Enter") {
              submitBtn.click();
            }
          });
        }
      };

      p.windowResized = function () {
        const wrapper = document.getElementById("ribbon-wrapper");
        p.resizeCanvas(wrapper.offsetWidth, window.innerHeight);
        p.background(0);
      };

      p.draw = function () {
        p.background(0);

        for (let i = ribbons.length - 1; i >= 0; i--) {
          ribbons[i].update();
          ribbons[i].display();
          if (ribbons[i].isOffscreen()) {
            ribbons[i].startFading();
          }
          if (ribbons[i].isFullyTransparent()) {
            ribbons.splice(i, 1);
          }
        }

        // Neue Ribbons hinzufügen
        if (p.random(1) < 0.008) {
          ribbons.push(
            new Ribbon(
              p,
              50,
              p.random(50, p.height - 50),
              p.random(ribbonColors),
              p.random(letters),
              letters,
              ribbonColors
            )
          );
        }

        // Im Museum neue Briefe hinzufügen
        if (newLetters.length > 0) {
          const latestNewLetter = newLetters[newLetters.length - 1];
          if (
            letters.length === 0 ||
            latestNewLetter !== letters[letters.length - 1]
          ) {
            ribbons.unshift(
              new Ribbon(
                p,
                50,
                p.random(50, p.height - 50),
                "white",
                latestNewLetter,
                letters,
                ribbonColors
              )
            );
            letters.push(latestNewLetter); // Füge den neuesten Brief zu letters hinzu
          }
        }
      };
    };

    const myp5 = new p5(ribbonSketch);

    // Funktion zum Herunterladen des Canvas als PNG
    function downloadCanvasAsPNG() {
      const canvas = myp5.canvas; // Hol das P5-Canvas
      const link = document.createElement("a"); // Erstelle einen Link
      link.href = canvas.toDataURL("image/png"); // Setze die URL des Bildes
      link.download = "saveland_loveletters.png"; // Setze den Download-Namen
      link.click(); // Simuliere den Klick auf den Link
    }

    // Event Listener für den Download-Button
    const downloadButton = document.getElementById("download_ribbons");
    if (downloadButton) {
      downloadButton.addEventListener("click", downloadCanvasAsPNG);
    }

    // Submit-Funktion
    // Submit-Funktion
    async function submitForm(event) {
      event.preventDefault();

      const inputEl = document.getElementById("ribbons_input");
      const selectEl = document.getElementById("ribbons_satzanfang");
      const submitBtn = document.getElementById("submitBtn"); // Der Submit-Button
      const selectedValue = selectEl.value;
      const data = inputEl.value.trim();

      // Daten aus den data-Attributen des Buttons lesen
      const noMessageText = submitBtn.getAttribute("data-no_message");
      const errorText = submitBtn.getAttribute("data-error");
      const badLanguageText = submitBtn.getAttribute("data-bad_language");

      if (data === "") {
        alert(noMessageText); // Zeige Alert für leeres Eingabefeld
        return;
      }

      const resultString = `${selectedValue} ${data}`;
      console.log(resultString);

      try {
        const response = await fetch("https://bonn.bildwerk.tv/receive/", {
          method: "POST",
          headers: {
            "Content-Type": "text/plain",
          },
          body: resultString,
        });

        const result = await response.json();
        console.log(result);

        if (result.status === "success") {
          // Füge die "sent"-Klasse zum Button hinzu
          submitBtn.classList.add("sent");

          // Entferne die Klasse "sent" nach 3 Sekunden
          setTimeout(() => {
            submitBtn.classList.remove("sent");
          }, 1000);

          // Erstelle ein neues Ribbon
          ribbons.unshift(
            new Ribbon(
              myp5,
              50,
              myp5.random(50, myp5.height - 50),
              "white",
              resultString,
              letters,
              ribbonColors
            )
          );
          inputEl.value = ""; // Leere das Eingabefeld nach dem Senden
        } else if (result.status === "censured") {
          alert(badLanguageText); // Zeige Alert für zensierte Sprache
        } else {
          alert(errorText); // Zeige Alert für andere Fehler
        }
      } catch (error) {
        console.error("Error:", error);
        alert(errorText); // Zeige Alert für Netzwerk- oder sonstige Fehler
      }
    }
  });
}
