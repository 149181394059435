import("three").then(function (THREE) {
  const canvasElement = document.getElementById("Globus");

  // Check if the element with ID "Globus" exists
  if (canvasElement) {
    let camera, scene, renderer;
    let globeMesh;
    let raycaster = new THREE.Raycaster();
    let mouse = new THREE.Vector2();
    let autoRotateSpeed = 0.001;
    let dragging = false;
    let animationId = null;

    init();

    // Setup Intersection Observer
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animate(); // Start animation
          } else {
            cancelAnimationFrame(animationId); // Stop animation
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(canvasElement);

    function init() {
      scene = new THREE.Scene();

      // Set camera and initial position
      camera = new THREE.PerspectiveCamera(
        75,
        getContainerWidth() / getCanvasHeight(), // Aspect ratio based on container width and calculated height
        0.1,
        1000
      );
      updateCameraPosition();

      // Add lighting
      const light = new THREE.AmbientLight(0xffffff, 0.2);
      scene.add(light);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 4);
      directionalLight.position.set(1, 1, 1).normalize();
      scene.add(directionalLight);

      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(
        wp.stylesheet_directory + "/graphics/GlobeTextures/standard.webp",
        function (texture) {
          texture.generateMipmaps = true;
          texture.minFilter = THREE.LinearMipmapLinearFilter;
          texture.magFilter = THREE.LinearFilter;
          texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
        }
      );

      globeMesh = createGlobe(texture);
      scene.add(globeMesh);

      renderer = new THREE.WebGLRenderer();
      renderer.setPixelRatio(window.devicePixelRatio);
      setCanvasSize(); // Set canvas size based on container
      canvasElement.appendChild(renderer.domElement);

      renderer.domElement.addEventListener("mousedown", onDocumentMouseDown);
      window.addEventListener("resize", onWindowResize);
    }

    function createGlobe(texture) {
      const baseRadius = 1;
      const geometry = new THREE.SphereGeometry(baseRadius, 100, 100);
      const material = new THREE.MeshStandardMaterial({ map: texture });
      return new THREE.Mesh(geometry, material);
    }

    // Function to get the width of #Globus
    function getContainerWidth() {
      return canvasElement.offsetWidth;
    }

    // Function to dynamically calculate the height of the canvas based on the container's width
    function getCanvasHeight() {
      const containerWidth = getContainerWidth();
      if (containerWidth < 600) {
        return containerWidth; // For smaller screens, set height to 75% of the width
      } else {
        return window.innerHeight; // Full height for larger screens
      }
    }

    // Function to set canvas size and update camera
    function setCanvasSize() {
      const containerWidth = getContainerWidth();
      const containerHeight = getCanvasHeight();
      renderer.setSize(containerWidth, containerHeight);
      camera.aspect = containerWidth / containerHeight;
      camera.updateProjectionMatrix();
      updateCameraPosition();
    }

    // Function to adjust the camera distance based on container width
    function updateCameraPosition() {
      const containerWidth = getContainerWidth();
      if (containerWidth < 600) {
        camera.position.z = 1.9; // Further away for smaller screens
      } else {
        camera.position.z = 2; // Closer for larger screens
      }
    }

    function onDocumentMouseDown(event) {
      event.preventDefault();

      if (event.target !== renderer.domElement) return;

      mouse.x = (event.clientX / getContainerWidth()) * 2 - 1;
      mouse.y = -(event.clientY / getCanvasHeight()) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(globeMesh);

      if (intersects.length > 0) {
        dragging = true;
        renderer.domElement.addEventListener("mousemove", onDocumentMouseMove);
        renderer.domElement.addEventListener("mouseup", onDocumentMouseUp);
      }
    }

    function onDocumentMouseMove(event) {
      if (!dragging) return;

      const movementX = event.movementX || event.mozMovementX || 0;
      const movementY = event.movementY || event.mozMovementY || 0;

      globeMesh.rotation.y += movementX * 0.01;
      globeMesh.rotation.x += movementY * 0.01;
    }

    function onDocumentMouseUp() {
      dragging = false;
      renderer.domElement.removeEventListener("mousemove", onDocumentMouseMove);
      renderer.domElement.removeEventListener("mouseup", onDocumentMouseUp);
    }

    function onWindowResize() {
      setCanvasSize(); // Update canvas size and camera aspect ratio
    }

    function animate() {
      animationId = requestAnimationFrame(animate);
      globeMesh.rotation.y += autoRotateSpeed;
      renderer.render(scene, camera);
    }

    const textures = [
      "/graphics/GlobeTextures/1.webp",
      "/graphics/GlobeTextures/2.webp",
      "/graphics/GlobeTextures/3.webp",
      "/graphics/GlobeTextures/4.webp",
      "/graphics/GlobeTextures/5.webp",
      "/graphics/GlobeTextures/6.webp",
    ];

    const loadedTextures = {};

    window.onload = function () {
      preloadTextures();
    };

    function preloadTextures() {
      const textureLoader = new THREE.TextureLoader();

      textures.forEach((texturePath, index) => {
        textureLoader.load(
          wp.stylesheet_directory + texturePath,
          function (texture) {
            loadedTextures[`texture${index + 1}`] = texture;
          }
        );
      });
    }

    for (let i = 1; i <= 6; i++) {
      const button = document.getElementById(`globe_texture${i}`);

      if (button) {
        button.addEventListener("click", function () {
          if (button.classList.contains("selected")) {
            button.classList.remove("selected");
            setStandardTexture();
          } else {
            removeSelectedClass();
            button.classList.add("selected");

            if (loadedTextures[`texture${i}`]) {
              globeMesh.material.map = loadedTextures[`texture${i}`];
              globeMesh.material.needsUpdate = true;
            } else {
              changeTexture(wp.stylesheet_directory + textures[i - 1]);
            }
          }
        });
      }
    }

    function removeSelectedClass() {
      for (let i = 1; i <= 6; i++) {
        const btn = document.getElementById(`globe_texture${i}`);
        if (btn) {
          btn.classList.remove("selected");
        }
      }
    }

    function setStandardTexture() {
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(
        wp.stylesheet_directory + "/graphics/GlobeTextures/standard.webp",
        function (texture) {
          texture.generateMipmaps = true;
          texture.minFilter = THREE.LinearMipmapLinearFilter;
          texture.magFilter = THREE.LinearFilter;
          texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
          globeMesh.material.map = texture;
          globeMesh.material.needsUpdate = true;
        }
      );
    }

    function changeTexture(texturePath) {
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(texturePath, function (newTexture) {
        newTexture.generateMipmaps = true;
        newTexture.minFilter = THREE.LinearMipmapLinearFilter;
        newTexture.magFilter = THREE.LinearFilter;
        newTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
        globeMesh.material.map = newTexture;
        globeMesh.material.needsUpdate = true;
      });
    }
  }
});
