export class Ribbon {
  constructor(
    p,
    x,
    y,
    color = null,
    inputText = null,
    saetze = [],
    ribbonColors = []
  ) {
    this.p = p;
    this.x = x;
    this.y = y;
    this.newX = this.x;
    this.newY = y;
    this.radius = this.p.random(10, 30);
    this.noiseOffsetX = this.p.random(1000);
    this.noiseOffsetY = this.p.random(1000);
    this.speed = this.p.random(0.5, 3);
    this.counter = 0;
    this.lines_xy = [];
    this.characters = [];
    this.ribbonColor = color ? color : this.p.random(ribbonColors);

    // Dynamische Größenanpassung basierend auf der Fensterbreite
    if (window.innerWidth < 900) {
      this.size = 15; // Kleinere Schriftgröße unter 700px Breite
      this.noiseScale = 1.15; // Größeres Noise-Level für mehr Bewegung
      this.typeOffset = 2;

      // Rechteckgröße für kleine Bildschirme definieren (wird einmal gesetzt)
      this.rectWidth = 15;
      this.rectHeight = 17;
    } else {
      this.size = 30; // Standardgröße über 700px Breite
      this.noiseScale = 2; // Standard Noise-Level
      this.typeOffset = 6;

      // Rechteckgröße für größere Bildschirme definieren (wird einmal gesetzt)
      this.rectWidth = 28;
      this.rectHeight = 30;
    }

    this.satz = inputText
      ? inputText.toUpperCase()
      : saetze.length > 0
      ? this.p.random(saetze).toUpperCase()
      : "SAVE LAND";
    this.satz = " " + this.satz.trim() + "  | ";

    this.alpha = 255;
    this.fadeRate = 5;
    this.fadeStarted = false;

    this.easingFactor = 0.2;
    this.charScale = 0;
    this.windOffset = this.p.random(1000);

    this.isLooping = false;
    this.loopStartFrame = 0;
    this.loopAngle = 0;
    this.loopingCompleted = false;
    this.loopOffsetX = this.p.random(-50, 50); // Zufällige Startposition-Verschiebung
    this.loopOffsetY = this.p.random(-30, 30); // Zufällige Endverschiebung

    // Zufällige Looping-Richtung: 1 für im Uhrzeigersinn, -1 für gegen den Uhrzeigersinn
    this.loopDirection = this.p.random([-1, 1]);
  }

  update() {
    this.p.textSize(this.size);

    // Seltenere Loopings (nur 0.25% Wahrscheinlichkeit)
    if (!this.isLooping && this.p.random(1) < 0.001) {
      this.isLooping = true;
      this.loopStartFrame = this.p.frameCount;
      this.loopAngle = 0;

      // Unterschiedliche und unregelmäßige Looping-Größen
      this.loopRadiusX = this.p.random(100, 300); // Zufällige Breite des Loopings
      this.loopRadiusY = this.p.random(100, 300); // Zufällige Höhe des Loopings
      this.loopDistortion = this.p.random(0.8, 1.5); // Unregelmäßigkeit für geschmierte Kreise
      this.loopOffsetX = this.p.random(-50, 50); // Zufällige Abweichung am Start
      this.loopOffsetY = this.p.random(-80, 80); // Zufällige Endverschiebung nach dem Looping
    }

    if (this.isLooping) {
      // Volle Kreisbewegung mit unregelmäßiger Endposition
      let angleSpeed = 0.1; // Geschwindigkeit für die Kreisbewegung
      this.loopAngle += angleSpeed * this.loopDirection; // Ändere die Richtung je nach loopDirection

      if (this.loopAngle <= this.p.TWO_PI && this.loopAngle >= -this.p.TWO_PI) {
        // Bewege dich durch den vollen Winkelbereich
        this.newX =
          this.x +
          this.loopOffsetX +
          this.p.cos(this.loopAngle) * this.loopRadiusX * this.loopDistortion;
        this.newY =
          this.y +
          this.loopOffsetY +
          this.p.sin(this.loopAngle) *
            this.loopRadiusY *
            (1 / this.loopDistortion);
      } else {
        // Beende den Looping und speichere die Endrichtung und Geschwindigkeit
        this.isLooping = false;
        this.loopingCompleted = true;

        // Berechne den Endwinkel des Loopings, um die Bewegung fortzusetzen
        this.loopEndAngle = this.p.atan2(
          this.newY - this.y,
          this.newX - this.x
        );
        this.speed = this.p.random(2, 5); // Geschwindigkeit nach dem Looping
      }
    }

    if (this.loopingCompleted) {
      // Bewege das Band in die Richtung des Endwinkels des Loopings
      this.newX += this.p.cos(this.loopEndAngle) * this.speed;
      this.newY += this.p.sin(this.loopEndAngle) * this.speed;

      // Beende den Übergang nach einer Weile
      if (this.p.dist(this.x, this.y, this.newX, this.newY) < 5) {
        this.loopingCompleted = false;
      }
    } else {
      // Normale Bewegung ohne Looping
      let noiseX = this.p.noise(this.noiseOffsetX);
      let noiseY = this.p.noise(this.noiseOffsetY);
      this.newX += this.speed; // Geschwindigkeit bleibt hier erhalten
      this.newY += this.p.map(noiseY, 0, 1, -2, 2);
      this.noiseOffsetX += 0.003;
      this.noiseOffsetY += 0.003;
    }

    let d = this.p.dist(this.x, this.y, this.newX, this.newY);
    let newLetter = this.satz.charAt(this.counter);
    let stepSize = this.p.textWidth(newLetter);

    this.lines_xy.push(this.p.createVector(this.x, this.y));

    if (d > stepSize) {
      let angle = this.p.atan2(this.newY - this.y, this.newX - this.x);
      this.characters.push({
        char: newLetter,
        x: this.x,
        y: this.y,
        angle: angle,
        scale: this.charScale,
        windNoiseOffset: this.p.random(1000),
      });

      this.counter++;
      if (this.counter >= this.satz.length) this.counter = 0;

      this.x = this.x + this.p.cos(angle) * stepSize;
      this.y = this.y + this.p.sin(angle) * stepSize;
    }

    if (this.fadeStarted) {
      this.alpha -= this.fadeRate;
    }

    for (let i = 0; i < this.characters.length; i++) {
      let charObj = this.characters[i];
      if (charObj.scale < 1) {
        charObj.scale += this.easingFactor;
        if (charObj.scale > 1) charObj.scale = 1;
      }
    }
  }

  display() {
    this.p.noFill();

    // Schatten zeichnen mit schnellerem Ausblenden
    const shadowAlpha = Math.max(0, this.alpha - 200); // Reduziert die Alpha-Transparenz des Schattens
    this.p.stroke(0, shadowAlpha); // Schwarz mit variabler Transparenz für den Schatten
    this.p.strokeWeight(this.size * 1.5);

    // Schatten zeichnen
    this.p.beginShape();
    for (let i = 0; i < this.characters.length; i++) {
      let windEffectX = this.p.map(
        this.p.noise(
          this.characters[i].windNoiseOffset + this.p.frameCount * 0.01
        ),
        0,
        1,
        -this.noiseScale,
        this.noiseScale
      );
      let windEffectY = this.p.map(
        this.p.noise(
          this.characters[i].windNoiseOffset + 1000 + this.p.frameCount * 0.01
        ),
        0,
        1,
        -this.noiseScale,
        this.noiseScale
      );

      // Leichte Versetzung für den Schatten
      this.p.curveVertex(
        this.characters[i].x + windEffectX + 5, // X-Versatz für den Schatten
        this.characters[i].y + windEffectY + 10 - this.size / 10 // Y-Versatz für den Schatten
      );
    }
    this.p.endShape();

    // Band zeichnen
    this.p.strokeCap(this.p.PROJECT);
    this.p.strokeJoin(this.p.ROUND);
    let ribbonColorWithAlpha = this.p.color(
      this.p.red(this.ribbonColor),
      this.p.green(this.ribbonColor),
      this.p.blue(this.ribbonColor),
      this.alpha
    );
    this.p.stroke(ribbonColorWithAlpha);
    this.p.noFill();

    // Band zeichnen
    this.p.beginShape();
    for (let i = 0; i < this.characters.length; i++) {
      let windEffectX = this.p.map(
        this.p.noise(
          this.characters[i].windNoiseOffset + this.p.frameCount * 0.01
        ),
        0,
        1,
        -this.noiseScale,
        this.noiseScale
      );
      let windEffectY = this.p.map(
        this.p.noise(
          this.characters[i].windNoiseOffset + 1000 + this.p.frameCount * 0.01
        ),
        0,
        1,
        -this.noiseScale,
        this.noiseScale
      );
      this.p.curveVertex(
        this.characters[i].x + windEffectX,
        this.characters[i].y + windEffectY - this.size / 10
      );
    }
    this.p.endShape();

    this.p.noStroke();
    let textColorWithAlpha = this.p.color(0, this.alpha);
    this.p.textSize(this.size);
    this.p.textAlign(this.p.LEFT);

    this.p.textFont("degular-variable");
    this.p.textSize(this.size);
    this.p.textAlign(this.p.LEFT);
    this.p.drawingContext.font = "italic 400 degular-variable";

    // Zeichne die Buchstaben
    for (let i = 0; i < this.characters.length - 2; i++) {
      let charObj = this.characters[i];
      this.p.push();
      let windEffectX = this.p.map(
        this.p.noise(charObj.windNoiseOffset + this.p.frameCount * 0.01),
        0,
        1,
        -this.noiseScale,
        this.noiseScale
      );
      let windEffectY = this.p.map(
        this.p.noise(charObj.windNoiseOffset + 1000 + this.p.frameCount * 0.01),
        0,
        1,
        -this.noiseScale,
        this.noiseScale
      );
      this.p.translate(charObj.x + windEffectX, charObj.y + windEffectY);
      this.p.rotate(charObj.angle);
      this.p.scale(charObj.scale);

      // Rechtecke nur ausblenden, während die Buchstaben sichtbar bleiben
      if (!this.fadeStarted) {
        this.p.fill(ribbonColorWithAlpha); // Färbung, wenn nicht am Ausblenden
        // Rechteckgröße wird jetzt basierend auf der im Konstruktor definierten Größe verwendet
        this.p.rect(
          0,
          -this.rectHeight / 2,
          this.rectWidth,
          this.rectHeight,
          5
        ); // Rechteck zeichnen
      }

      // Buchstaben immer zeichnen
      this.p.fill(textColorWithAlpha); // Buchstabenfarbe
      this.p.text(charObj.char, 0, this.typeOffset);

      this.p.pop();
    }
  }

  isOffscreen() {
    if (this.x > this.p.width + this.radius + 500 && !this.fadeStarted) {
      return true;
    }
    return false;
  }

  startFading() {
    this.fadeStarted = true;
  }

  isFullyTransparent() {
    return this.alpha <= 0;
  }
}
