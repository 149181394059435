//import x from "y"
require("./js/_custom-vh.js");
document.addEventListener("DOMContentLoaded", function () {
  import("flickity").then(function (Flickity) {
    const flickityGalleryOptions = {
      freeScroll: false,
      wrapAround: true,
      pageDots: false,
      adaptiveHeight: true,
    };
    // Finde alle Galerien mit der Klasse "flickity-gallery" und initialisiere Flickity
    const galleries = document.querySelectorAll(".flickity-gallery");
    galleries.forEach((gallery) => {
      gallery.querySelectorAll("*").forEach((child) => {
        if (child.tagName.toLowerCase() === "figure") {
          child.className = "carousel-cell"; // Fügt nur den `figure`-Elementen die Klasse "carousel-cell" hinzu
        } else {
          child.className = ""; // Entfernt alle Klassen von anderen Kind-Elementen
        }
      });
      // Setze die classList des Eltern-Elements nur auf "flickity-gallery"
      gallery.className = "flickity-gallery";
      new Flickity(gallery, flickityGalleryOptions);
    });
  });

  //Tooltips:
  const tooltip = document.getElementById("tooltip");
  let activeElement = null;

  // Function to update the tooltip position
  function updateTooltipPosition(event) {
    const tooltipRect = tooltip.getBoundingClientRect();
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    let tooltipX = event.pageX + 10; // Offset from the cursor
    let tooltipY = event.pageY + 10;

    // Adjust the tooltip to not go off-screen horizontally
    if (tooltipX + tooltipRect.width > screenWidth) {
      tooltipX = event.pageX - tooltipRect.width - 10;
    }

    // Adjust the tooltip to not go off-screen vertically
    if (tooltipY + tooltipRect.height > screenHeight) {
      tooltipY = event.pageY - tooltipRect.height - 10;
    }

    tooltip.style.left = tooltipX + "px";
    tooltip.style.top = tooltipY + "px";
  }

  // Function to show tooltip and update its text
  function showTooltip(event) {
    const element = event.target;
    const tooltipText = element.getAttribute("data-tooltip");
    if (tooltipText) {
      tooltip.textContent = tooltipText;
      tooltip.style.display = "block";
      activeElement = element;
    }
  }

  // Function to hide tooltip
  function hideTooltip() {
    tooltip.style.display = "none";
    activeElement = null;
  }

  // Attach mouse events to all elements with data-tooltip
  document.querySelectorAll("[data-tooltip]").forEach((element) => {
    element.addEventListener("mouseenter", showTooltip);
    element.addEventListener("mouseleave", hideTooltip);
    element.addEventListener("mousemove", (event) => {
      if (activeElement === element) {
        updateTooltipPosition(event);
      }
    });
  });

  require("./js/_scrolldir_detector.js");
  //require('./js/_heroRibbon.js')
  require("./js/_globe.js");
  require("./js/_stickerGenerator.js");
  require("./js/_ribbons.js");
});
