if (document.getElementById("stickerGenerator")) {
  import("flickity").then(function (Flickity) {
    const flickityOptions = {
      contain: true,
      pageDots: false,
      wrapAround: true,
      setGallerySize: false,
      selectedAttraction: 0.2,
      friction: 0.8,
    };

    const slider_statement = new Flickity(
      "#slider_statement",
      flickityOptions
    ).on("change", () => {
      startAnimation("statement");
    });
    const slider_symbol = new Flickity("#slider_symbol", flickityOptions).on(
      "change",
      () => {
        startAnimation("symbol");
      }
    );
    const slider_backdrop = new Flickity(
      "#slider_backdrop",
      flickityOptions
    ).on("change", () => {
      startAnimation("backdrop");
    });

    const sticker_canvas = document.createElement("canvas");
    const sticker_ctx = sticker_canvas.getContext("2d");
    document.getElementById("sticker-output").appendChild(sticker_canvas);

    sticker_canvas.width = 591;
    sticker_canvas.height = 591;

    update_sticker_canvas();

    let animationType = ""; // Welches SVG animiert wird
    let rotationAngle = -30; // Startet bei 30 Grad
    let targetAngle = 0;
    let animationFrameId;

    function startAnimation(type) {
      cancelAnimationFrame(animationFrameId); // Laufende Animation abbrechen
      animationType = type;
      rotationAngle = 40; // Zurücksetzen auf 30 Grad für die neue Animation
      animateToZero();
    }

    function animateToZero() {
      const easingFactor = 0.2; // Sorgt für einen sanften Ease-Out

      // Drehung bis zum Zielwinkel (0 Grad)
      if (Math.abs(rotationAngle - targetAngle) > 0.1) {
        rotationAngle += (targetAngle - rotationAngle) * easingFactor;
        update_sticker_canvas();
        animationFrameId = requestAnimationFrame(animateToZero);
      } else {
        rotationAngle = 0; // Endgültig auf 0 Grad setzen
        update_sticker_canvas();
      }
    }

    function getSymbolImageUrl(index_symbol, index_statement) {
      const suffixes = ["a", "a", "b", "b", "c", "c", "d", "d"];
      const suffix = suffixes[index_statement - 1] || "a";
      return `${wp.stylesheet_directory}/graphics/StickerGenerator/symbol/${index_symbol}_${suffix}.svg`;
    }

    async function update_sticker_canvas() {
      const imageUrls = [
        `${wp.stylesheet_directory}/graphics/StickerGenerator/statement/${
          slider_statement.selectedIndex + 1
        }.svg`,
        `${wp.stylesheet_directory}/graphics/StickerGenerator/backdrop/${
          slider_backdrop.selectedIndex + 1
        }.svg`,
        getSymbolImageUrl(
          slider_symbol.selectedIndex + 1,
          slider_statement.selectedIndex + 1
        ),
      ];

      try {
        const images = await Promise.all(imageUrls.map(fetchSvgImage));
        sticker_ctx.clearRect(
          0,
          0,
          sticker_canvas.width,
          sticker_canvas.height
        );
        sticker_ctx.fillStyle = "#adadad";
        sticker_ctx.fillRect(0, 0, sticker_canvas.width, sticker_canvas.height);

        images.forEach((image, index) => {
          const type =
            index === 0 ? "statement" : index === 1 ? "backdrop" : "symbol";

          sticker_ctx.save();
          if (type === animationType) {
            sticker_ctx.translate(
              sticker_canvas.width / 2,
              sticker_canvas.height / 2
            );
            sticker_ctx.rotate((rotationAngle * Math.PI) / 180);
            sticker_ctx.translate(
              -sticker_canvas.width / 2,
              -sticker_canvas.height / 2
            );
          }

          sticker_ctx.drawImage(
            image,
            0,
            0,
            sticker_canvas.width,
            sticker_canvas.height
          );

          sticker_ctx.restore();
        });
      } catch (error) {
        console.error("Fehler beim Laden der Bilder:", error);
      }
    }

    const sticker_download_button = document.getElementById("generate_sticker");
    sticker_download_button.addEventListener("click", download_sticker);

    async function download_sticker() {
      try {
        const scaleFactor = 4; // Skaliere den Canvas für höhere Auflösung
        const largerCanvas = document.createElement("canvas");
        const largerCtx = largerCanvas.getContext("2d");

        largerCanvas.width = sticker_canvas.width * scaleFactor;
        largerCanvas.height = sticker_canvas.height * scaleFactor;

        const imageUrls = [
          `${wp.stylesheet_directory}/graphics/StickerGenerator/statement/${
            slider_statement.selectedIndex + 1
          }.svg`,
          `${wp.stylesheet_directory}/graphics/StickerGenerator/backdrop/${
            slider_backdrop.selectedIndex + 1
          }.svg`,
          getSymbolImageUrl(
            slider_symbol.selectedIndex + 1,
            slider_statement.selectedIndex + 1
          ),
        ];

        const images = await Promise.all(imageUrls.map(fetchSvgImage));

        largerCtx.clearRect(0, 0, largerCanvas.width, largerCanvas.height);

        images.forEach((image) => {
          largerCtx.drawImage(
            image,
            0,
            0,
            largerCanvas.width,
            largerCanvas.height
          );
        });

        // Download des größeren Canvas als PNG
        largerCanvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "saveLandSticker.png";
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, "image/png");
      } catch (error) {
        console.error(
          "Fehler beim Zusammenführen und Herunterladen der Bilder:",
          error
        );
      }
    }

    async function fetchSvgImage(url) {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Fehler beim Laden des Bildes von ${url}`);
      }
      const svgText = await response.text();
      const blob = new Blob([svgText], { type: "image/svg+xml" });
      const objectURL = URL.createObjectURL(blob);

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          URL.revokeObjectURL(objectURL);
          resolve(img);
        };
        img.onerror = reject;
        img.src = objectURL;
      });
    }
  });
}
