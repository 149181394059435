// Funktion zur Überprüfung der Scrollrichtung
function checkScrollDirection() {
    var lastScrollTop = 0;
    window.addEventListener("scroll", function() {
        var currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        var scrollAmount = Math.abs(currentScroll - lastScrollTop);
        if (scrollAmount >= 3 * parseFloat(getComputedStyle(document.documentElement).fontSize)) {
            if (currentScroll > lastScrollTop) {
                // nach unten scrollen
                document.body.classList.remove('scroll-up');
                document.body.classList.add('scroll-down');
            } else {
                // nach oben scrollen
                document.body.classList.remove('scroll-down');
                document.body.classList.add('scroll-up');
            }
            lastScrollTop = currentScroll;
        }
    }, false);
}

// Aufrufen der Funktion beim Laden der Seite
window.onload = function() {
    checkScrollDirection();
};